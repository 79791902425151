<template>
  <div>
    <skgmenu />
    <div class="cooperation">
      <div class="banner"></div>
      <div class="content">
        <div class="title">公司扶持轻松开店</div>
        <div class="desc" style="margin-bottom: 20px">
          专业团队|全案输出|无忧开店
        </div>
        <img
          class="img"
          src="https://files.skg.com/skg/2022-11-26/4e936bc8-3d77-4e29-849a-3fe72205fc35.png"
          alt=""
        />
      </div>
      <div class="content">
        <div class="title">6大扶持政策</div>
        <div class="desc">
          我们承诺
          <span style="color: #b90e0e">0加盟费</span>
          ，更有6大政策支持，助力合伙人快速成长
        </div>
      </div>
      <div class="content">
        <div class="supperLine showPc">
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/dafa6753-e0db-405d-b322-b864577b3ac4.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">专业门店装修支持</div>
            <div class="supperDeac">提供全套设计方案及免费装修道具</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/843d0cfe-afec-40fb-9b76-641c2a1eec74.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">专业培训支持</div>
            <div class="supperDeac">定期团队运营及产品销售技能培训</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/8a3d977e-6f85-4756-a02d-75569e2e8b87.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">品牌宣传支持</div>
            <div class="supperDeac">明星代言人+线上线下营销推广</div>
          </div>
        </div>
        <div class="supperLine showPc">
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/dd79ea6c-25f2-4245-b19c-f06373fe9164.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">开业活动支持</div>
            <div class="supperDeac">策划新店开业活动并赠送活动物料</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/365a487b-d19d-433b-8744-66343771e1d9.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">销售政策支持</div>
            <div class="supperDeac">Mall店政策+台奖销售激励</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/f2fd8533-3e18-4790-81fa-8e1c05c826a4.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">区域保护支持</div>
            <div class="supperDeac">严格规范区域运营制度 保障合伙人权益</div>
          </div>
        </div>
        <div class="supperLine showMobile">
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/dafa6753-e0db-405d-b322-b864577b3ac4.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">专业门店装修支持</div>
            <div class="supperDeac">提供全套设计方案及免费装修道具</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/843d0cfe-afec-40fb-9b76-641c2a1eec74.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">专业培训支持</div>
            <div class="supperDeac">定期团队运营及产品销售技能培训</div>
          </div>
        </div>
        <div class="supperLine showMobile">
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/8a3d977e-6f85-4756-a02d-75569e2e8b87.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">品牌宣传支持</div>
            <div class="supperDeac">明星代言人+线上线下营销推广</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/dd79ea6c-25f2-4245-b19c-f06373fe9164.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">开业活动支持</div>
            <div class="supperDeac">策划新店开业活动并赠送活动物料</div>
          </div>
        </div>
        <div class="supperLine showMobile">
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/365a487b-d19d-433b-8744-66343771e1d9.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">销售政策支持</div>
            <div class="supperDeac">Mall店政策+台奖销售激励</div>
          </div>
          <div class="supperItem">
            <img
              src="https://files.skg.com/skg/2022-11-26/f2fd8533-3e18-4790-81fa-8e1c05c826a4.png"
              alt=""
              class="supperImg"
            />
            <div class="supperTit">区域保护支持</div>
            <div class="supperDeac">严格规范区域运营制度 保障合伙人权益</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div
          src="https://files.skg.com/skg/2022-11-26/380735a3-4639-4bdc-af7c-c76f2adef7e8.png"
          alt=""
          class="img channel"
        ></div>
      </div>
      <div class="content showPc">
        <img
          style="width: 350px"
          src="https://files.skg.com/skg/2022-11-26/aa7730dc-b4ba-4656-b980-dbd28d959ee6.png"
          alt=""
        />
        <div class="flexContent">
          <img
            src="https://files.skg.com/skg/2022-11-28/4b3177b9-032b-4af9-8147-ac49fd452007.png"
            alt=""
          />
          <img
            src="https://files.skg.com/skg/2022-11-28/e80b45ea-3b8e-492b-8573-1daf91f8c65a.png"
            alt=""
          />
          <img
            src="https://files.skg.com/skg/2022-11-28/2801dc9f-9dd0-4d5d-a8f0-160492b61937.png"
            alt=""
          />
          <img
            src="https://files.skg.com/skg/2022-11-28/f9a3a504-3a15-4ec9-9f84-dad24b6e04b7.png"
            alt=""
          />
        </div>
      </div>
      <div class="showMobile">
        <img
          class="mobileImg"
          src="https://files.skg.com/skg/2022-11-27/f6230700-2923-4fa7-a0f5-bdf3d1c7d484.png"
          alt=""
        />
      </div>
      <div>
        <div class="colorBlue">欢迎加入SKG</div>
        <div class="partner">让我们合作共赢 只做第一</div>
        <div class="info">
          <div class="barcode">
            <img
              src="https://files.skg.com/skg/2022-11-27/5fce0a85-bf10-4f78-916b-7ba320aeebf8.png"
              alt=""
            />
            <div class="barcodeTxt">扫一扫 获取招商信息</div>
          </div>
          <div class="userinfo">
            <div class="contactInformation">
              <img
                src="https://files.skg.com/skg/2022-11-27/c726ad79-ba2f-4958-8bee-1b173d365571.png"
                alt=""
                class="icon"
              />
              <div class="contactTxt">
                <div class="contactTitle">招商负责人</div>
                <div class="contactDesc">陈经理</div>
              </div>
            </div>
            <div class="contactInformation">
              <img
                src="https://files.skg.com/skg/2022-11-27/dc8e5b22-58a7-4500-bacc-72b0d6414d4a.png"
                alt=""
                class="icon"
              />
              <div class="contactTxt">
                <div class="contactTitle">招商热线</div>
                <div class="contactDesc">0755-88866686</div>
                <div class="contactDesc">18029352739</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="tit">请留下您的信息，免费获取更多品牌加盟政策</div>
        <div class="formItem">
          <div class="formLable">名称*</div>
          <input type="text" v-model="mail_name" class="formInput" />
        </div>
        <div class="formItem">
          <div class="formLable">邮箱*</div>
          <input type="text" v-model="mail_email" class="formInput" />
        </div>
        <div class="formItem">
          <div class="formLable">意向合作渠道/产品*</div>
          <input type="text" v-model="mail_product" class="formInput" />
        </div>
        <div class="formItem">
          <div class="formLable">联系地址</div>
          <input type="text" v-model="mail_addr" class="formInput" />
        </div>
        <div class="formItem">
          <div class="formLable">联系电话*</div>
          <input type="text" v-model="mail_phone" class="formInput" />
          <div class="getCaptcha" @click="getCaptcha">{{ captcha }}</div>
        </div>
        <div class="formItem">
          <div class="formLable">验证码*</div>
          <input type="text" v-model="in_capcha" class="formInput" />
        </div>
        <div class="submitBtn" @click="send">提交信息</div>
      </div>
    </div>
    <myfooter />
  </div>
</template>
<script>
import skgmenu from "@/components/Modules/menu/menu.vue";
import myfooter from "@/components/Modules/footer/footer.vue";
export default {
  name: "cooperation",
  components: {
    skgmenu,
    myfooter,
  },
  data() {
    return {
      title: "SKG商务合作_合作让我们共赢未来-SKG官网",
      description:
        "SKG商务合作,合作让我们共赢未来。SKG作为时尚的颈椎按摩仪品牌，集研发、生产、销售、培训为一体，主要经营颈椎按摩仪,护颈仪,颈部按摩器,颈椎按摩器等。",
      Keywords: "SKG商务合作",
      scene: null,
      status: 0,
      status_tip: "",
      mail_email: null,
      mail_phone: null,
      mail_name: null,
      mail_company: null,
      mail_product: null,
      mail_addr: null,
      in_capcha: null,
      captchaVal: null,
      phoneVal: null,
      timer: null,
      captcha: "获取验证码",
      isTruePhone: false,
    };
  },
  watch: {
    status(val) {
      if (val === 1) {
        setTimeout(() => {
          this.status = 0;
        }, 3000);
      }
    },
  },
  mounted() {
    document.title = this.title;
    document
      .querySelector('meta[name="Keywords"]')
      .setAttribute("content", this.Keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.description);
  },
  methods: {
    getCaptcha() {
      if (this.isTruePhone) {
        return;
      }
      if (
        !/^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[6]([8|6])\d{5}$/.test(
          this.mail_phone
        )
      ) {
        this.tips = "电话不正确";
        this.$message.warning("电话不正确");
        this.status = 1;
        return;
      }

      let opt = {
        url: "https://zhishi.skg.com/web.kb/app/captcha/" + this.mail_phone,
        method: "get",
      };
      this.$http(opt).then((res) => {
        this.isTruePhone = true;
        if (res.code === 0) {
          let tmp = opt.url.split("/");
          this.phoneVal = tmp[tmp.length - 1];
          this.captchaVal = res.captcha;
        }
        this.refeshCap();
      });
    },
    refeshCap() {
      let countDownNum = 60;
      this.timer = setInterval(() => {
        countDownNum--;
        if (countDownNum > 0) {
          this.captcha = `${countDownNum}s后重试`;
        } else {
          this.captcha = "获取验证码";
          this.isTruePhone = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    send() {
      // console.log(this.captchaVal)
      // console.log(this.in_capcha);
      var reg = new RegExp(
        "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
      );
      if (!this.mail_name) {
        this.status_tip = "请输入姓名！";
        this.$message.warning("请输入姓名！");
        this.status = 1;
        return;
      }
      if (!this.mail_email) {
        this.status_tip = "请输入邮箱！";
        this.$message.warning("请输入邮箱！");
        this.status = 1;
        return;
      }
      if (!reg.test(this.mail_email)) {
        this.status_tip = "邮箱格式不正确，请重新输入！";
        this.$message.warning("邮箱格式不正确，请重新输入！");
        this.status = 1;
        return;
      }
      if (!this.mail_product) {
        this.status_tip = "请输入渠道/产品！";
        this.$message.warning("请输入渠道/产品！");
        this.status = 1;
        return;
      }
      if (!this.mail_phone) {
        this.status_tip = "请输入联系电话！";
        this.$message.warning("请输入联系电话！");
        this.status = 1;
        return;
      }
      if (
        !/^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[6]([8|6])\d{5}$/.test(
          this.mail_phone
        )
      ) {
        this.status_tip = "手机号不正确，请重新输入！";
        this.$message.warning("手机号不正确，请重新输入！");
        this.status = 1;
        return;
      }
      if (!this.in_capcha) {
        this.status_tip = "请输入验证码！";
        this.$message.warning("请输入验证码！");
        this.status = 1;
        return;
      }
      if (this.captchaVal != this.in_capcha) {
        this.status_tip = "验证码不正确，请重新输入！";
        this.$message.warning("验证码不正确，请重新输入！");
        this.status = 1;
        return;
      }
      let opt = {
        url: "https://cn.skg.com/service/web/zhaoshang/sendEmail",
        // url:'http://10.102.104.151:8080/skg/web/zhaoshang/sendEmail',
        method: "post",
        params: {
          title: `官网中文招商信息`,
          msg: `来自官网中文（cn.skg.com）招商信息<br>联系邮箱：${this.mail_email}<br>联系电话：${this.phoneVal}<br>联系地址：${this.mail_addr}<br>联系人：${this.mail_name}<br>合作渠道/意向产品：${this.mail_product}<br>`,
        },
      };
      this.status_tip = "正在提交你的资料...";
      this.$message.success("正在提交你的资料");
      this.status = 2;
      this.$http(opt).then((res) => {
        if (res.msg === "success") {
          this.status_tip = "你的资料已提交成功";
          this.$message.success("你的资料已提交成功");
          this.status = 1;
          this.mail_name = null;
          this.mail_email = null;
          this.mail_product = null;
          this.in_cap = null;
          this.mail_addr = null;
          this.mail_phone = null;
          this.this.captchaVa = null;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .cooperation {
    padding-top: 50px;
    background: #fff;
    .banner {
      width: 100vw;
      height: 144vw;
      background-size: cover;
      background-position: center;
      background-image: url("https://files.skg.com/skg/2022-11-28/01d0154e-00f0-4ca9-bc8a-4a7f16101c3a.png");
    }
    .showPc {
      display: none !important;
    }
    .showMobile {
      display: flex !important;
    }
    .content {
      text-align: center;
    }
    .title {
      font-size: 26px;
      color: #333;
      margin: 30px 0 0 0;
      line-height: 36px;
      text-align: center;
    }
    .desc {
      font-size: 16px;
      color: #333;
      margin: 0 0 30px 20%;
      width: 60%;
    }
    .img {
      width: 80%;
    }
    .supperLine {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 18px;
    }
    .supperItem {
      width: 200px;
      height: 164px;
    }
    .supperImg {
      width: 150px;
      height: 89px;
    }
    .channel {
      width: 80vw;
      height: 150vw;
      margin: 10vw 0 0 10vw;
      background-size: cover;
      background-position: center;
      background-image: url("https://files.skg.com/skg/2022-11-27/13bf2fb9-45fa-47ab-9f6b-07cff38cf1b8.png");
    }
    .supperTit {
      margin-top: 10px;
      font-size: 15px;
      color: #42d7c8;
      line-height: 24px;
    }
    .mobileImg {
      width: 100vw;
      margin-top: 60px;
    }
    .supperDeac {
      font-size: 12px;
      line-height: 24px;
    }
    .half {
      text-align: center;
      width: 50%;
      margin: 200px auto 0;
    }
    .flexContent {
      width: 1380px;
      display: flex;
      margin-top: 58px;
      justify-content: space-between;
      img {
        width: 300px;
      }
    }
    .colorBlue {
      color: #42d7c8;
      text-align: center;
      font-size: 26px;
      margin-top: 42px;
    }
    .partner {
      font-size: 16px;
      margin-top: 11px;
      text-align: center;
    }
    .info {
      width: 100vw;
      margin: 30px auto 0px;
    }
    .barcode {
      width: 70vw;
      height: 70vw;
      margin: 5vw 15vw 0vw 15vw;
      background: #ecf3f6;
      padding: 0 0 5vw 0;
      img {
        width: 50vw;
        height: 50vw;
        margin: 10vw 10vw 0vw 10vw;
      }
      .barcodeTxt {
        text-align: center;
        font-size: 18px;
        line-height: 50px;
      }
    }
    .userinfo {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      padding-top: 20px;
      .contactInformation {
        padding: 0px 0 30px 80px;
        .icon {
          width: 45px;
          height: 45px;
          float: left;
        }
        .contactTxt {
          float: left;
          padding-left: 19px;
          .contactTitle {
            color: #333;
            font-size: 16px;
            line-height: 27px;
          }
          .contactDesc {
            color: #42d7c8;
            font-size: 16px;
            line-height: 27px;
          }
        }
      }
    }
    .form {
      width: 90vw;
      margin: 50px auto 0;
      background: #f8fafb;
      padding: 50px 28px;
      box-sizing: border-box;
      .tit {
        color: #333;
        font-size: 16px;
        padding: 0 0 50px 0;
        text-align: center;
      }
      .formItem {
        border-bottom: 1px solid #090103;
        position: relative;
        margin-bottom: 16px;
        .formLable {
          font-size: 14px;
          color: #333;
          margin-bottom: 10px;
        }
        .formInput {
          width: 80%;
          height: 30px;
          font-size: 16px;
          box-sizing: border-box;
          padding: 0px 0 0 24px;
          background-color: #f8fafb;
        }
        .getCaptcha {
          width: 80px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          position: absolute;
          right: 0;
          bottom: 2px;
          background-color: #42d7c8;
          font-size: 12px;
        }
      }
      .submitBtn {
        width: 60%;
        height: 35px;
        text-align: center;
        line-height: 35px;
        font-size: 16px;
        background-color: #42d7c8;
        margin: 0 0 10px 20%;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .cooperation {
    padding-top: 65px;
    background: #fff;
    margin: 0 auto;
    .banner {
      width: 100vw;
      margin: 0 auto;
      height: 44vw;
      background-size: cover;
      background-position: center;
      background-image: url("https://files.skg.com/skg/2022-11-28/1c1ecd8b-3a50-4f3c-b63b-600b8ad32385.png");
    }
    .showPc {
      display: flex !important;
    }
    .showMobile {
      display: none !important;
    }
    .content {
      width: 1380px;
      margin: 70px auto 0;
      text-align: center;
      display: block !important;
    }
    .title {
      font-size: 50px;
      color: #333;
      line-height: 71px;
      text-align: center;
    }
    .desc {
      font-size: 30px;
      color: #333;
    }
    .img {
      width: 100%;
    }
    .supperLine {
      display: flex;
      justify-content: space-between;
    }
    .supperItem {
      float: left;
      width: 380px;
      margin: 21px 0 21px 0;
    }
    .supperImg {
      width: 380px;
      height: 224px;
    }
    .channel {
      width: 100%;
      height: 800px;
      background-size: cover;
      background-position: center;
      background-image: url("https://files.skg.com/skg/2022-11-26/380735a3-4639-4bdc-af7c-c76f2adef7e8.png");
    }
    .supperTit {
      margin: 21px 0 0 10px;
      font-size: 35px;
      color: #333;
    }
    .supperDeac {
      font-size: 24px;
    }
    .half {
      text-align: center;
      width: 50%;
      margin: 200px auto 0;
    }
    .flexContent {
      width: 100%;
      display: flex;
      margin-top: 58px;
      justify-content: space-between;
      img {
        width: 300px;
      }
    }
    .colorBlue {
      color: #42d7c8;
      text-align: center;
      font-size: 50px;
      margin-top: 72px;
    }
    .partner {
      font-size: 32px;
      margin-top: 21px;
      text-align: center;
    }
    .info {
      width: 1200px;
      height: 580px;
      margin: 70px auto 200px;
    }
    .barcode {
      width: 600px;
      height: 100%;
      background: #ecf3f6;
      float: left;
      margin: 0;
      padding: 0;
      img {
        width: 345px;
        height: 345px;
        margin: 100px 0 0 128px;
      }
      .barcodeTxt {
        text-align: center;
        font-size: 28px;
        line-height: 50px;
      }
    }
    .userinfo {
      width: 600px;
      height: 100%;
      float: left;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 150px 100px;
      .contactInformation {
        .icon {
          width: 100px;
          height: 100px;
          float: left;
        }
        .contactTxt {
          float: left;
          padding-left: 30px;
          .contactTitle {
            color: #333;
            font-size: 34px;
            line-height: 60px;
          }
          .contactDesc {
            color: #42d7c8;
            font-size: 34px;
            line-height: 50px;
          }
        }
      }
    }
    .form {
      width: 1200px;
      margin: 100px auto 0;
      background: #f8fafb;
      padding: 0px 68px;
      box-sizing: border-box;
      .tit {
        color: #333;
        font-size: 34px;
        padding: 50px 0px;
        text-align: center;
      }
      .formItem {
        border-bottom: 1px solid #090103;
        position: relative;
        margin-bottom: 92px;
        .formLable {
          font-size: 24px;
          color: #333;
        }
        .formInput {
          width: 80%;
          height: 60px;
          font-size: 24px;
          box-sizing: border-box;
          padding-left: 24px;
          background-color: #f8fafb;
        }
        .getCaptcha {
          width: 180px;
          height: 55px;
          text-align: center;
          line-height: 55px;
          position: absolute;
          right: 0;
          bottom: 2px;
          background-color: #42d7c8;
          font-size: 18px;
        }
      }
      .submitBtn {
        width: 100%;
        height: 55px;
        text-align: center;
        line-height: 55px;
        font-size: 18px;
        background-color: #42d7c8;
        margin-bottom: 60px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1380px) {
  .cooperation .content {
    width: 100vw;
  }
  .cooperation {
    zoom: 0.8;
  }
}
</style>

